import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../Breadscrum";
import LinkButton from "../pohrby/LinkButton";
import Phone from "../../assets/img/telefon.svg";
import Sipka from "../../assets/img/sipkaW.png";
import OverenaFirma from "../pohrby/OverenaFirma";
import Seznam from "../../assets/img/seznam.png";
import Forbes from "../../assets/img/forbes.png";
import iDnes from "../../assets/img/idnes.png";
import PozitivniZpravy from "../../assets/img/pozitivniZpravy.png";
import E15 from "../../assets/img/e15trans.png";
import StartupJobs from "../../assets/img/startupJobs.png";
import CzechCrunch from "../../assets/img/ccLogo.svg";
import HN from "../../assets/img/hn_logo.svg";
import Denik from "../../assets/img/denik.png";
import Aktualne from "../../assets/img/aktualne.png";
import Firma4 from "../../assets/img/firma4.png";
import Logo from "../../assets/img/logo_new.svg";


const StatsInner = styled.section`
  background: #B9CAB9;
  padding: 60px 0;
  margin-top: 100px;
  position: relative;
  z-index: 2;

  .main {
    @media (max-width: 899px) {
      padding: 60px 0;
      padding-bottom: 0;
    }


    h2 {
      font-family: NewKansas;
      font-size: 36px;
      color: #052205;
      text-align: center;
      line-height: 46px;
      margin: 0 auto;
      width: calc(100% - 100px);
      max-width: 1150px;
      margin-top: 80px;

      
      @media (max-width: 899px) {
        font-size: 28px;
        width: calc(100% - 50px);
        line-height: 35px;
        text-align: left;
        max-width: 600px;
        margin-top: 0;
      }

      @media (max-width: 430px) {
        font-size: 25px;
        line-height: 33px;
        text-align: left;
      }

    }

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 30px;
      max-width: 1150px;
      width: calc(100% - 100px);
      margin: 60px auto;

      @media (max-width: 1250px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 60px;
      }

      @media (max-width: 899px) {
        grid-template-columns: 1fr;
        width: calc(100% - 50px);
        max-width: 600px;
        grid-gap: 40px;
        margin: 40px auto;
      }


      li {
        width: 100%;
        list-style: none;

        @media (max-width: 899px) {
          margin: 0px auto;
          width: calc(100%);
        }

        .number {
          font-family: NewKansas-Bold;
          font-size: 50px;
          color: #fff;
          display: block;

          @media (max-width: 430px) {
            font-size: 36px;
          }

        }

        .desc {
          font-family: Visuelt-Medium;
          font-size: 20px;
          color: #052205;
          display: block;
          margin-bottom: 20px;
          margin-top: 10px;

          @media (max-width: 430px) {
            font-size: 18px;
            margin-bottom: 15px;
            margin-top: 5px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 17px;
          color: #2B2F2B;
          line-height: 25px;

          @media (max-width: 430px) {
            font-size: 16px;
            line-height: 24px;
          }
          
          span {
            font-family: Visuelt-Regular;
            white-space: nowrap;
          }
        }
      }
    }

    .linkButton {
      button {
        font-family: Visuelt-Medium;
        font-size: 18px;
        color: #2B2F2B;
        text-align: center;
        background: #fff;
        border-radius: 5px;
        margin: 0 auto;

        @media (max-width: 899px) {
          margin-left: calc((100% - 600px)/2);
        }

        @media (max-width: 649px) {
          margin-left: 25px;
        }

        &:hover {
          background: #EBEFEB;
          cursor: pointer;
        }
      }
    }
  }

  .press {
    background: #FFFFFF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 20px;
    width: calc(100% - 100px - 60px);
    max-width: 1150px;
    margin: 0 auto;
    margin-top: -140px;
    padding: 30px;
    z-index: 1;
    position: relative;

    @media (max-width: 899px) {
      width: calc(100% - 50px - 60px);
      max-width: 600px;
    }

    @media (max-width: 390px) {
      width: calc(100% - 20px - 40px);
      padding: 20px;
    }

    h2 {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #000000;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 10px;

      @media (max-width: 899px) {
        text-align: left;
      }
    }

    ul {
      display: block;
      margin: 0 auto;
      width: calc(100%);
      text-align: center;


      @media (max-width: 899px) {
        text-align: left;
      }

      li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 30px;
        margin-top: 20px;



        &:last-child {
          margin-right: 0px;

        }

        &:hover {
          img {
            filter: grayscale(100%) brightness(0%) opacity(60%);
          }
        }

        img {
          filter: grayscale(100%) brightness(0%) opacity(35%);
          max-height: 20px;
          width: auto;

          @media (max-width: 380px) {
            max-height: 18px;
          }
        }

        .jobs {
          height: 16px;

          @media (max-width: 380px) {
            max-height: 14px;
          }
        }

        .seznam {
          max-height: 24px;

          @media (max-width: 380px) {
            max-height: 22px;
          }
        }

        .aktualne {
          max-height: 35px;

          @media (max-width: 380px) {
            max-height: 30px;
          }
        }
      }
    }
  }

  .companyTestemonial {
    padding: 80px 0;
    background: #FFFFFF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 20px;
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: -200px;


    @media (max-width: 899px) {
      max-width: 600px;
      width: calc(100% - 50px);
      padding: 60px 0;
      margin-top: 60px;
    }

    @media (max-width: 420px) {
      padding: 40px 0;
      width: calc(100% - 20px);
    }

    img {
      height: 40px;
      display: block;
      margin: 0 auto;
    }

    p {
      margin: 50px auto;
      font-family: NewKansas;
      font-size: 32px;
      line-height: 42px;
      color: #151515;
      text-align: center;
      max-width: 800px;
      width: calc(100% - 100px);

      @media (max-width: 899px) {
        line-height: 35px;
        font-size: 28px;
        width: calc(100% - 50px);
        margin: 40px auto;
      }

      @media (max-width: 600px) {
        line-height: 30px;
        font-size: 23px;
      }

      @media (max-width: 420px) {
        font-size: 20px;
        line-height: 25px;
        margin: 30px auto;
        width: calc(100% - 30px);
      }
    }

    span {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #5f5f5f;
      text-align: center;
      display: block;
      margin: 0 auto;
    }
  }
`;





const Stats = ({ setModal }) => {
  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const isPlus1000 = useMediaQuery({
    query: '(min-width: 1000px)'
  });

  return (
    <StatsInner>

      <div className="press">
        <h2>Můžete nás znát z:</h2>
        <ul>   
          <li>
            <a target="_blank" href="https://www.e15.cz/byznys/obchod-a-sluzby/digitalizovane-pohrebnictvi-start-up-goodbye-chce-revoluci-v-oboru-1379081">
              <img src={E15} alt="Digitalizované pohřebnictví. Start-up Goodbye chce revoluci v oboru"/>
            </a>
          </li>

          <li>
            <a target="_blank" href="https://forbes.cz/pohrebnictvi-funguje-uz-150-let-stejne-ted-to-chce-zmenit-cesky-startup/">
              <img src={Forbes} alt="Pohřebnictví funguje už 150 let stejně. Teď to chce změnit český startup"/>
            </a>
          </li>

          <li>
            <a target="_blank" href="https://zpravy.aktualne.cz/domaci/ekologicke-pohrbivani/r~a321b27ed6a911eca9eeac1f6b220ee8/">
              <img className="aktualne" src={Aktualne} alt="Pohřeb bez rakve, do lesa i na louku. Za pár let běžné, věří expert ministerstva"/>
            </a>
          </li>

          <li>
            <a target="_blank" href="https://podcasty.hn.cz/c1-67010510-co-nas-ceka-po-smrti-i-silne-konzervativni-obor-jako-pohrebnictvi-ma-sve-trendy">
              <img className="pozitivni" src={HN} alt="Co nás čeká po smrti? I silně konzervativní obor jako pohřebnictví má své trendy"/>
            </a>
          </li>

          <li>
            <a target="_blank" href="https://cc.cz/moderni-pohreb-na-par-kliknuti-cesky-startup-goodbye-ziskava-na-rozvoj-sve-sluzby-miliony-korun/">
              <img className="jobs" src={CzechCrunch} alt="Moderní pohřeb na pár kliknutí. Český startup Goodbye získává na rozvoj své služby miliony korun"/>
            </a>
          </li>

          <li>
            <a target="_blank" href="https://www.idnes.cz/zpravy/domaci/data-internet-uzivatele-spolecnost-nabidka-sluzby-pohreb-umrti-online-zavet-notari.A220413_151632_domaci_lre">
              <img src={iDnes} alt="Digitální pozůstalost. Uspořádat je třeba účty na sítích, potíž je s kryptoměnami"/>
            </a>
          </li>

          <li>
            <a target="_blank" href="https://www.seznamzpravy.cz/clanek/ani-smrt-nas-nerozdeli-stale-vice-lidi-chce-mit-data-v-digitalnim-trezoru-172228">
              <img className="seznam" src={Seznam} alt="Ani smrt nás nerozdělí. Stále více lidí chce mít data v digitálním trezoru"/>
            </a>
          </li>

          <li>
            <a target="_blank" href="https://brnensky.denik.cz/zpravy_region/pohrby-v-brne-vedou-kremace-s-obradem-pritomnost-kneze-chce-jen-17-lidi-20220425.html">
              <img src={Denik} alt="Pohřby v Brně: vedou kremace s obřadem. Přítomnost kněze chce jen 17% lidí"/>
            </a>
          </li>
        </ul>
      </div>

      <div className="main" id="vysledky">
        <h2 data-aos="new-animation" data-aos-delay="000">Prokazatelně pomáháme lidem šetřit čas a především se cítit lépe</h2>
        <ul>
          <li data-aos="new-animation" data-aos-delay="200">
            <span className="number">1 000+</span>
            <span className="desc">obsloužených rodin</span>
            <p>Již 3 roky pomáháme stovkám rodin se lépe vypořádat se smrtí blízkého. S průměrným hodnocením 4,9/5 hvězdiček.</p>
          </li>
          <li data-aos="new-animation" data-aos-delay="400">
            <span className="number">25 %</span>
            <span className="desc">ušetřeného času</span>
            <p>Už během úvodního rozhovoru poznáme zákazníkovu situaci a přijdeme s plánem, jak mu ušetřit co nejvíce času a starostí.</p>
          </li>
          <li data-aos="new-animation" data-aos-delay="600">
            <span className="number">24/7</span>
            <span className="desc">concierge servis</span>
            <p>Váš zaměsntanec se na nás může s důvěrou s čímkoliv obrátit. Naši empatiční experti jsou tu pro něj ve dne v noci.</p>
          </li>
          <li data-aos="new-animation" data-aos-delay="800">
            <span className="number">800tis. Kč</span>
            <span className="desc">na 100 zapojených lidí za rok</span>
            <p>Vám a vašim lidem šetříme spoustu ztracených peněz a produktivity. Nemluvě o přátelštější kultuře a zlepšeném psychickém zdraví.</p>
          </li>
        </ul>
        <LinkButton func={() => setModal(true)} to="https://calendly.com/jiristepanek" text={"Domluvit ukázku služby"} />
      </div>

      <div className="companyTestemonial">
        {/* <img data-aos="new-animation" data-aos-delay="200" src={Firma4} alt="Firma" /> */}
        <img data-aos="new-animation" data-aos-delay="200" src={Logo} alt="Firma" style={{"height": 30}} />
        <p data-aos="new-animation" data-aos-delay="00">“Care je benefit, kterým jasně dáváte najevo, že se o svůj tým postaráte opravdu za každé situace.”</p>
        <span data-aos="new-animation" data-aos-delay="000">— Jiří Štěpánek, CEO</span>
      </div>
    </StatsInner>
  );
}











export default Stats;
