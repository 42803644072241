import React, { useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import LinkButton from "../pohrby/LinkButton";
import careRating from "../../assets/img/careRating.svg";
import careRating2 from "../../assets/img/careRating2.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import Quotes from "../../assets/img/quotes.svg";
import Rozpis from "./Rozpis";
import axios from "axios";




const InspiraceInner = styled.section`
 .inspirace {
    margin-top: 80px;
    
    @media (max-width: 899px) {
      margin-top: 60px;
    }

    @media (max-width: 430px) {
      margin-top: 40px;
    }

    h2 {
      margin: 0 auto;
      font-family: NewKansas;
      font-size: 36px;
      color: #2c4668;
      text-align: center;
      line-height: 46px;
      margin-bottom: 25px;
      width: calc(100% - 100px);
      max-width: 900px;

      @media (max-width: 899px) {
        max-width: 600px;
        width: calc(100% - 50px);
        font-size: 32px;
        line-height: 43px;
        text-align: center;
        margin-bottom: 0px;
      }

      @media (max-width: 430px) {
        font-size: 25px;
        text-align: left;
        line-height: 35px;
      }
    }

    .bubliny {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      max-width: 1150px;
      margin: 0 auto;
      margin-top: 60px;
      width: calc(100% - 100px);

      @media (max-width: 899px) {
        display: block;
        width: calc(100% - 50px);
        max-width: 600px;
        margin-top: 40px;
      }

      @media (max-width: 430px) {
        width: calc(100% - 20px);
      }
      
      .bublina {
        background: #FFFFFF;
        box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
        border-radius: 20px;
        width: calc(100%/3 - 20px - 50px);
        padding: 25px;

        @media (max-width: 899px) {
          width: calc(100% - 50px);
          margin: 0 auto;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @media (max-width: 430px) {
          padding: 15px;
          width: calc(100% - 30px);
        }

        div {
          display: block;
          width: 100%;
          height: 4px;
          background: #000000;
          border-radius: 5px;
          margin-bottom: 15px;
        }

        .red {
          background: #F0C0AF;
        }

        .green {
          background: #BECDB3;
        }

        .blue {
          background: #BDCFE1;
        }

        span {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #221605;
          display: block;
        }

        h3 {
          font-family: Visuelt-Medium;
          font-size: 23px;
          color: #000000;
          margin-top: 10px;
          margin-bottom: 15px;

          @media (max-width: 430px) {
            font-size: 21px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 17px;
          color: #666666;
          line-height: 24px;

          @media (max-width: 430px) {
            font-size: 16px;
          }
        }

        button {
          font-family: Visuelt-Medium;
          text-decoration: none;
          font-size: 17px;
          color: #221605;
          background: none;
          border: 2px solid #CFC8C4;
          padding: 10px 20px;
          display: block;
          width: fit-content;
          border-radius: 5px;
          margin-top: 20px;
          cursor: pointer;

          &:hover {
            border: 2px solid #B2A196;
          }
        }

        input {
          font-family: Visuelt-Regular;
          font-size: 17px;
          color: #000;
          background: #FFFFFF;
          border: 2px solid #CFC8C4;
          border-radius: 5px;
          padding: 10px;
          width: calc(100% - 24px);
          margin-top: 15px;
        }

        .msg {
          font-family: Visuelt-Regular;
          font-size: 17px;
          color: #644f40;
          border: 2px solid #CFC8C4;
          padding: 10px;
          border-radius: 5px;
          margin-top: 10px;
        }

        .err {
          border: 0;
          padding: 0;
          color: #FF6060;
        } 
      }
    }
  }
`;



const DownloadWithEmail = ({text, filename, url, video}) => {

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [phase, setPhase] = useState(1);
  const [isLoading, setIsLoading] = useState(false);


  if (phase === 1) {
    return (
      <button onClick={() => setPhase(2)}>{text}</button>
    )
  }

  else if (phase === 2) {
    return (
      <form onSubmit={e => e.preventDefault()}>
        <input type="email" placeholder="Váš e-mail" value={email} onChange={e => setEmail(e.target.value)} />
        <button onClick={() => {
          if (!email) {
            setError("Prosím vyplňte e-mail");
            return;
          }

          if (!email.includes("@")) {
            setError("Prosím vyplňte platný e-mail");
            return;
          }

          if (!email.includes(".")) {
            setError("Prosím vyplňte platný e-mail");
            return;
          }

          if (isLoading) {
            return;
          }

          setIsLoading(true);
          let backendUrl = "";

          if (process.env.NODE_ENV === 'development') {
            backendUrl = 'http://localhost:1337'
          }

          if (process.env.NODE_ENV === 'production') {
            backendUrl = 'https://goodbye-backend.herokuapp.com'
          }

          axios.post(`${backendUrl}/auth/local`, {
            identifier: 'app@goodbye.cz',
            password: 'Good.Bye123',
          }).then((res) => {
            axios.post(`${backendUrl}/poslimail`, {
              "to": "stepanek@goodbye.cz",
              "from": 'podpora@goodbye.cz',
              "subject": `Nové stažení materiálů na GB Care`,
              "text": `Nové stažení materiálů. Hurá, máme tu další<br/><br/>Jméno: neznáme<br/>E-mail: ${email}<br/>Firma: neznáme<br/>Od kud se dozvědeli: neznáme`,
              "html": `Nové stažení materiálů. Hurá, máme tu další<br/><br/>Jméno: neznáme<br/>E-mail: ${email}<br/>Firma: neznáme<br/>Od kud se dozvědeli: neznáme`,
            },
            {
              headers: {
                "Authorization": `Bearer ${res.data.jwt}`,
                "Content-Type": "application/json"
              },
            }).then(() => {

              if (video) {
                window.open(url, '_blank');
                setIsLoading(false);
                setPhase(3);
                return;
              }

              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                filename,
              );
          
              // Append to html link element page
              document.body.appendChild(link);
          
              // Start download
              link.click();

              setIsLoading(false);
              setPhase(3);
            });
          }).catch(() => {
            setError("Něco se pokazilo, zkuste to prosím znovu");
            setIsLoading(false);
          });
        }}>
          {isLoading ? "Načítání..." : text}
        </button>

        {error && <p className="msg err">{error}</p>}
      </form>
    );
  }

  else if (phase === 3) {
    return (
      <p className="msg">Děkujeme za stažení materiálů.</p>
    )
  }

  
}


const Inspirace = ({ setModal }) => {
  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  const isPlus1000 = useMediaQuery({
    query: '(min-width: 1000px)'
  });


  
  

  return (
   
    <InspiraceInner>
      <div className="inspirace">
        <h2 data-aos="new-animation" data-aos-delay="000">Inspirujte se, jak zlepšit procesy ve vaší společnosti</h2>

        <div className="bubliny">

          <div className="bublina" data-aos="new-animation" data-aos-delay="200">
            <div className="red"></div>
            <span>Příručka pro HR</span>
            <h3>Úmrtí v rodině zaměstnance</h3>
            <p>V příručce se dozvíte všechny tipy a návody na to, jak správně pomoci zaměstnanci při úmrtí v rodině.</p>
            <DownloadWithEmail text="Stáhnout E-book" filename="Příručka pro empatické zaměstnavatele.pdf" url="/prirucka.pdf" />
          </div>

          <div className="bublina" data-aos="new-animation" data-aos-delay="400">
            <div className="green"></div>
            <span>Infografika</span>
            <h3>Jaký má dopad úmrtí na pracoviště?</h3>
            <p>Zjistěte, jaký má úmrtí skrytý dopad na vaší společnost a její kulturu.</p>
            <DownloadWithEmail text="Stáhnout PDF" filename="Jaký má dopad úmrtí na pracoviště.pdf" url="/DopadNaPracoviste.pdf" />
          </div>

          <div className="bublina" data-aos="new-animation" data-aos-delay="600">
            <div className="blue"></div>
            <span>Video</span>
            <h3>Ukázka platformy Care</h3>
            <p>Video ukázka a proklikání s komentářem všemi hlavními sekcemi webové aplikace Goodbye Care.</p>
            <DownloadWithEmail text="Přehrát video" video url="https://vimeo.com/864052882" />
          </div>
        </div>

      </div>
    </InspiraceInner>
  );
}

export default Inspirace;




