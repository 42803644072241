import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../Breadscrum";
import LinkButton from "../pohrby/LinkButton";
import Phone from "../../assets/img/telefon.svg";
import Sipka from "../../assets/img/sipkaW.png";
import OverenaFirma from "../pohrby/OverenaFirma";
import Seznam from "../../assets/img/seznam.png";
import Forbes from "../../assets/img/forbes.png";
import iDnes from "../../assets/img/idnes.png";
import PozitivniZpravy from "../../assets/img/pozitivniZpravy.png";
import E15 from "../../assets/img/e15trans.png";
import StartupJobs from "../../assets/img/startupJobs.png";
import CzechCrunch from "../../assets/img/ccLogo.svg";
import HN from "../../assets/img/hn_logo.svg";
import Denik from "../../assets/img/denik.png";
import Aktualne from "../../assets/img/aktualne.png";
import JumbotronV2 from "../brandingV2/JumbotronV2";
import LinkButtonV2 from "../brandingV2/LinkButtonV2";


const JumbotronInner = styled.section`
  .wrap {
    .left {
      max-width: 680px;
    }
  }


  h1 {
    font-size: 45px !important;
    margin-top: 0 !important;

    @media (max-width: 1150px) {
      font-size: 38px !important;
    }

    @media (max-width: 600px) {
      font-size: 33px !important;
      line-height: 43px !important;
    }

    @media (max-width: 420px) {
      font-size: 27px !important;
      line-height: 35px !important;
    }
  }

  .jumboImage {
    @media (max-width: 1150px) {
      width: 330px !important;
      height: 369px !important;
    }

    @media (max-width: 899px) {
      width: 253px !important;
      height: 280px !important;
      margin: 00px -30px;
      margin-top: -20px;
    }

    @media (max-width: 430px) {
      width: 200px !important;
      height: 220px !important;
      margin: 00px -25px;
      margin-top: -10px;
    }

    /* @media (max-width: 1150px) {
      width: 271px;
      height: 303px;
    } */
  }
  
  button {
    @media (max-width: 360px) {
      width: 100% !important;
    }
  }
`;





const JumbotronCare = ({ setModal }) => {
  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const isPlus1000 = useMediaQuery({
    query: '(min-width: 1000px)'
  });








  return (
    <StaticQuery
      query = {graphql`
        query {
          careJumbo: file(relativePath: { eq: "careJumbo2.png" }) {
            childImageSharp {
              fixed(height: 460, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          careJumboMin: file(relativePath: { eq: "careJumbo2.png" }) {
            childImageSharp {
              fixed(height: 280, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <JumbotronInner>
          <JumbotronV2
            noSplatky
            noReview
            animateH1
            showCompanies
            jumboObrazek={data.careJumbo.childImageSharp.fixed}
            jumboObrazekMobile={data.careJumboMin.childImageSharp.fixed}
            h1={"Podpořte své zaměstnance v nejtěžším období jejich života"}
            hideSecondHeading
            p={<>Úmrtí blízkého je velice náročná situace pro každého z nás. Má obrovský dopad na naše duševní zdraví, práci a každodenní aktivity.
              <br /><br />
              Osobně vaše zaměstnance provedeme všemi administrativními povinnostmi a podpoříme je v těch nejtěžších měsících jejich života. To vše díky našim empatickým expertům na telefonu a cenově dostupné platformě Care.</>}
            reviewDesc={<>Nejlépe hodnocená pohřební služba za 2024 <span className="nowrapReview">(4,95/5 průměrné hodnocení)</span></>}
            breadscrum={
              <Breadscrum
                visible={false}
                stranky={[
                  {name: "Domů", url: "/"},
                  {name: "Goodbye Care", url: "/care"},
                ]}
              />
            }
            customCta={[
              <LinkButtonV2 func={() => setModal(true)} arrow text="Domluvit ukázku služby" />,
            ]}
          />
        </JumbotronInner>
      )}
    />
  );
}











export default JumbotronCare;
