import React, { useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import LinkButtonV2 from "../brandingV2/LinkButtonV2";
import Check from "../../assets/img/peceCheck.svg";
import careIlustraceKruhy from "../../assets/img/careIlustraceKruhy.svg";
import blueCheck from "../../assets/img/blueCheck.svg";
import greenCheck from "../../assets/img/greenCheck2.svg";
import noCheck from "../../assets/img/noCheck.svg";
import Quotes from "../../assets/img/quotes.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

import Next from "../../assets/img/next.svg";
import Prev from "../../assets/img/prev.svg";

SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

const rozpisSluzeb = [
  {
    nazev: "Přístup do aplikace",
    appOnly: "Ano",
    standard: "Ano",
  },
  {
    nazev: "Personalizovaný úkolníček",
    appOnly: "Ano",
    standard: "Ano",
  },
  {
    nazev: "Knihovna 300+ návodů a postupů",
    appOnly: "Ano",
    standard: "Ano",
  },
  {
    nazev: "Průvodce truchlením",
    appOnly: "Ano",
    standard: "Ano",
  },
  {
    nazev: "Úložiště souborů",
    appOnly: "Ano",
    standard: "Ano",
  },
  {
    nazev: "Tvorba parte",
    appOnly: "Maximálně 3 revize",
    standard: "Neomezeně revizí",
  },
  {
    nazev: "Převody smluv, účtů, předplatných, členství",
    appOnly: "Maximálně 3 účty",
    standard: "Neomezeně účtů",
  },
  {
    nazev: "Rušení sociálních sítí, E-mail, Netflix, Spotify",
    appOnly: "Maximálně 3 účty",
    standard: "Neomezeně účtů",
  },
  {
    nazev: "Průvodce dědickým řízením",
    appOnly: "Pouze psané články",
    standard: "Články i osobní konzultace",
  },
  {
    nazev: "24/7 asistenční služba (chat, videohovor, e-mail)",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Úvodní hovor a plánování s naším expertem",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Asistence při organizaci pohřbu",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Zastupování na matrice",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Vyplacení částky životního pojištění",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Možnost přizvat rodinné příslušníky",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Převod energií, telefonu, internetu, TV",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Zrušení důchodu zesnulého",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Převod zahraničních bankovních účtů, PayPal",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Zjištění dluhů",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Komunikace s kameníkem a hřbitovy",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Zařízení nekrologu",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Obvolávání blízkých",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Žádost o sirotčí a vdovský důchod",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Žádost o pohřebné a ostatní sociální příspěvky",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Stabilizační služby",
    appOnly: "Ne",
    standard: "Ano",
  },
  {
    nazev: "Pohřební služba od A-Z",
    appOnly: "Od 14 900 Kč",
    standard: "Od 14 900 Kč",
  },
  {
    nazev: "Rodinný mediátor",
    appOnly: "Ne",
    standard: "Přes ověřeného partnera*",
  },
  {
    nazev: "Terapie",
    appOnly: "Ne",
    standard: "Přes ověřeného partnera*",
  },

  {
    nazev: "Účetní služby",
    appOnly: "Ne",
    standard: "Přes ověřeného partnera*",
  },
  {
    nazev: "Právní služby",
    appOnly: "Ne",
    standard: "Přes ověřeného partnera*",
  },
  {
    nazev: "Realitní služby",
    appOnly: "Ne",
    standard: "Přes ověřeného partnera*",
  },
  {
    nazev: "Správa majetku a finanční poradenství",
    appOnly: "Ne",
    standard: "Přes ověřeného partnera*",
  },
  {
    nazev: "Vyklizení a ocenění nemovitosti",
    appOnly: "Ne",
    standard: "Přes ověřeného partnera*",
  },
  {
    nazev: "Paliativní služby",
    appOnly: "Ne",
    standard: "Přes ověřeného partnera*",
  },
  {
    nazev: "Podpůrná skupina pro děti",
    appOnly: "Ne",
    standard: "Přes ověřeného partnera*",
  },
  {
    nazev: "Podpurná skupina na FB",
    appOnly: "Ano",
    standard: "Ano",
  },

]


const RozpisInner = styled.section`
  margin-top: 60px;

  @media (max-width: 899px) {
    margin-top: 00px;
  }

 .top {
    width: 100%;
    position: relative;
    padding-top: 60px;

  
    @media (max-width: 680px) {
      padding-bottom: 40px;
    }

    @media (max-width: 420px) {
      padding-top: 40px;
    }

    h2 {
      position: relative;
      z-index: 1;
      font-family: NewKansas;
      font-size: 36px;
      color: #142743;
      text-align: center;
      line-height: 50px;
      margin-bottom: 100px;

      @media (max-width: 899px) {
        max-width: 600px;
        width: calc(100% - 50px);
        font-size: 32px;
        line-height: 43px;
        margin-bottom: 80px;
      }

      @media (max-width: 680px) {
        margin-bottom: 40px;
      }

      @media (max-width: 430px) {
        font-size: 25px;
        line-height: 35px;
      }
    }

    .cards {
      position: relative;
      z-index: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 auto;
      max-width: 1000px;
      margin-top: 30px;
      width: calc(100% - 100px);

      @media (max-width: 899px) {
        width: calc(100% - 50px);
      }

      @media (max-width: 680px) {
        display: block;
      }

      @media (max-width: 430px) {
        width: calc(100% - 20px);
      }

      .card {
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        position: relative;

        @media (max-width: 899px) {
          height: calc(100% - 40px);
          width: calc(100% - 40px);
        }
        
        @media (max-width: 680px) {
          height: fit-content;
          width: calc(100% - 60px);
          max-width: 400px;
          margin: 0 auto;
        }

        @media (max-width: 430px) {
          width: calc(100% - 30px);
          padding: 20px 15px !important;
        }

        h3 {
          font-family: Visuelt-Medium;
          font-size: 20px;
          color: #1E1B26;

          @media (max-width: 899px) {
            font-size: 18px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 18px;
          color: #1E1B26;
          margin-bottom: 5px;
          margin-top: 10px;

          @media (max-width: 899px) {
            font-size: 17px;
          }
          
          span {
            font-family: Visuelt-Bold;
            font-size: 38px;

            @media (max-width: 899px) {
              font-size: 30px;
            }
          }
        }

        .nejoblibenejsi {
          font-family: Visuelt-Medium;
          font-size: 16px;
          color: #51964B;
          background: #D0ECCE;
          padding: 5px 10px;
          border-radius: 20px;
          position: absolute;
          top: 60px;
          right: 30px;

          @media (max-width: 899px) {
            font-size: 15px;
            top: 35px;
          }

          @media (max-width: 680px) {
            top: 30px;
          }

          @media (max-width: 430px) {
            position: static;
            margin-bottom: 20px;
            margin-top: -10px;
            display: block;
            width: fit-content;
          }
        }

        .rocne {
          opacity: 0.6;
          font-family: Visuelt-Regular;
          font-size: 18px;
          color: #000000;
          margin-bottom: 30px;
          display: block;

          @media (max-width: 899px) {
            font-size: 16px;
          }
        }

        button {
          @media (max-width: 680px) {
            width: 100%;
          }
        }

        ul {
          margin-top: 30px;

          li {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            &:last-child {
              @media (max-width: 680px) {
                margin-bottom: 0px;
              }
            }

            img {
              margin-right: 10px;
            }

            span {
              font-family: Visuelt-Regular;
              font-size: 18px;
              color: #1E1B26;

              @media (max-width: 899px) {
                font-size: 17px;
                line-height: 24px;
              }
            }
          }
        }

        &:first-child {
          background: rgba(255,255,255,0.77);
          border-radius: 20px 0 0 0;
          padding: 30px;

          @media (max-width: 899px) {
            padding: 20px;
          }

          @media (max-width: 680px) {
            border-radius: 20px;
            padding: 30px;

          }
        }

        &:last-child {
          background: #FFFFFF;
          box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
          border-radius: 20px;
          padding: 60px 30px;
          margin-top: -30px;
          margin-bottom: -70px;

          @media (max-width: 899px) {
            padding: 50px 20px;
            margin-bottom: -60px;
            margin-top: -30px;
          }

          @media (max-width: 680px) {
            border-radius: 20px;
            margin-top: 30px;
            padding: 30px;
            margin-bottom: 0px;
          }

          button {
            background: #71996e;

            &:hover {
              background: #5e8a5f;
            }
          }
        }
      }
    }

    .background {
      background: #CDD6DE;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
  }

  .table {
    margin: 0 auto;

    margin-top: 100px;
    max-width: 1150px;
    width: calc(100% - 50px);
    position: relative;



    @media (max-width: 680px) {
      position: relative;
      /* overflow: scroll; */
      overflow-x: ${({rozbaleno}) => rozbaleno ? "scroll" : "hidden"};
      margin-top: 40px;
      height: calc(100% + 2px);
      overflow-y: hidden;
      padding-bottom: 5px;
    }

    .nadpis {
      border-top: 1px solid rgba(17, 24, 39, 0.1);
      display: grid;
      column-gap: 30px;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      @media (max-width: 680px) {
        width: 700px;
        height: fit-content;

        display: ${({rozbaleno}) => rozbaleno ? "grid" : "none"};
      }

      div {
        margin-top: -1px;
        border-top: 2px solid none;
        padding-top: 40px;

        h3 {
          color: #0B0B0B;
          font-family: Visuelt-Bold;
          font-size: 20px;
          margin-bottom: 10px;
        }

        &:nth-child(3) {
          border-top: 2px solid #3A557C;

          h3 {
            color: #3A557C;
          }
        }

        &:last-child {
          border-top: 2px solid #5E9F59;

          h3 {
            color: #1e7a17;
          }
        }
      }

      .ukazatele {
        position: relative;

        h3 {
          position: absolute;
          bottom: 0px;
          left: 0;
          margin: 0;
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        line-height: 24px;
        color: #1E1B26;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      
    }

    .sluzby {
      position: relative;
      /* margin: 0 -15px; */

      margin-top: 60px;
      width: calc(100%);

      @media (max-width: 899px) {
        margin-top: 30px;
      }

      @media (max-width: 680px) {
        width: 700px;
        height: fit-content;
        margin-top: 00px;

      }

      .pozadi {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        /* border-top: 1px solid rgba(17, 24, 39, 0.1); */
        display: grid;
        column-gap: 30px;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        &:before {
          display: block;
          content: "";
        }

        div {
          width: 100%;
          width: calc(100% - 2px);
          height: 100%;
          border-radius: 20px;
          background: #fff;


          /* &:nth-child(2) {
            border: 2px solid #3A557C;
          } */
          border: 1px solid rgba(17, 24, 39, 0.1);

          &:last-child {
            /* border: 2px solid #5E9F59; */
            border: 2px solid #5e9f59;
            width: calc(100% - 4px);
          }

          /* &:first-child {
            background: none;
            box-shadow: none;
            border-radius: none;
          } */
        }
      }


      

      table {
        position: relative;
        width: 100%;
        /* border-collapse: separate; */
        /* border-spacing: 15px; */
        border-spacing: 0px;

        


        thead {
          span {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border-width: 0;
          }
        }

        tbody {
          
          tr {
            &:last-child {
              th {
                div {
                  display: none;
                }
              }
            }

            display: grid;
            column-gap: 30px;
            grid-template-columns: repeat(3, minmax(0, 1fr));

            th {
              /* width: calc((100% - 60px)/3); */
              padding: 20px 0;
              padding-right: 10px;
              text-align: left;
              font-family: Visuelt-Regular;
              font-size: 16px;
              color: #1E1B26;
              font-weight: normal !important;
              /* height: 30px; */
              line-height: 30px;
              
              span {
                display: inline-block;
                vertical-align: middle;
              }

              div {
                position: absolute;
                height: 1px;
                left: 0;
                right: 0;
                background: rgba(17, 24, 39, 0.1);
                margin-top: 19px;
              }
            }

            td {
              /* width: calc(100%/3 - 20px); */
              padding: 0;
              /* padding-right: 10px;
              padding-left: 10px; */
              padding: 0 10px;
              text-align: center;
              position: relative;
              height: 30px;
              line-height: 30px;
              height: calc(100%);
              width: calc(100% - 20px);
              display: flex;
              align-items: center;
              justify-content: center;
              
              span {
                display: inline-block;
                vertical-align: middle;
                font-family: Visuelt-Regular;
                font-size: 16px;
                color: #1E1B26;
                font-weight: normal !important;
              }

              img {
                margin: 0 auto;
                height: 30px;
                display: block;
              }

              /* &:nth-child(2) {
                padding: 10px 0;
                padding-right: 15px;
                text-align: left !important;
              } */
            }

            .standartSluzba {
              span {
                color: #1e7a17;
              }
            }
          }
        }
      }

    }

    .blurSekce {
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: 0;
        right: 0;
        height: 200px;
        background: rgb(251,251,250);
        background: linear-gradient(0deg, rgba(251,251,250,1) 0%, rgba(251,251,250,0) 100%);
        backdrop-filter: blur(1px);

        @media (max-width: 680px) {
          height: calc(100% - 100px);
          backdrop-filter: blur(0);
          background: linear-gradient(0deg, rgba(251,251,250,1) 0%, rgba(251,251,250,0.4) 100%);
        }

        button {
          display: block;
          margin: 0 auto;
          background: #cfd6e0;
          /* border: 2px solid #CFC8C4; */
          border-radius: 5px;
          padding: 10px 20px;
          font-family: Visuelt-Medium;
          font-size: 17px;
          color: #142743;
          margin-top: 150px;

          cursor: pointer;
          border: 0;

          &:hover {
            background: #bdcfe1;
          }
        }
      }

    .sluzbyHidden {
      overflow-y: hidden;
      overflow-x: visible;
      height: 500px;

      @media (max-width: 680px) {
        height: 300px;
      }
    }

  }

  .persona {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: calc(1150px - 100px);
    margin: 0 auto;
    width: calc(100% - 100px - 100px);
    background: #fff;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 20px;
    padding: 50px;

    @media (max-width: 899px) {
      display: block;
      padding: 25px;
      width: calc(100% - 100px );
      max-width: 550px;
    }

    @media (max-width: 600px) {
      padding: 25px;
      width: calc(100% - 100px);
    }

    @media (max-width: 430px) {
      padding: 15px;
      width: calc(100% - 50px);
    }





    .obrazekSmall {
      @media (max-width: 1100px) {
        width: 255px !important;
        height: 252px !important;
      }

      @media (max-width: 600px) {
        width: 180px !important;
        height: 220px !important;
        margin: -18px;
        margin-bottom: -5px;
      }

      @media (max-width: 430px) {
        margin: 0px;
      }
    }
  

    .text {
      width: calc(100% - 400px);

      

      @media (max-width: 1100px) {
        width: calc(100% - 280px);
      }

      @media (max-width: 899px) {
        width: 100%;
      }

      h3 {
        font-family: NewKansas-Bold;
        font-size: 30px;
        color: #142743;
        line-height: 50px;
        margin-bottom: 10px;

        @media (max-width: 899px) {
          font-size: 25px;
          line-height: 40px;
        }
        
      }

      span {
        font-family: Visuelt-Italic;
        font-size: 21px;
        color: #142743;
        display: block;

        @media (max-width: 899px) {
          font-size: 18px;
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 18px;
        color: #3E3E3E;
        line-height: 26px;
        margin-top: 30px;

        @media (max-width: 899px) {
          font-size: 17px;
          line-height: 24px;
        }

        @media (max-width: 430px) {
          font-size: 16px;
          line-height: 23px;
        }
      }

      ul {
        margin-top: 30px;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          list-style: none;

          img {
            margin-right: 15px;
          }

          span {
            font-family: Visuelt-Regular;
            font-size: 18px;
            color: #000000;

            @media (max-width: 899px) {
              font-size: 17px;
            }

            @media (max-width: 430px) {
              font-size: 16px;
              line-height: 23px;
            }
          }
        }
      }
    }
  }

  .swiper-pagination-bullet-active {
    background: #142743;
  }

  .swiper-container {
    padding-top: 30px !important;
  } 

  .personaH2 {
    margin-top: 90px;
    color: #142743;
    font-size: 36px;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      font-size: 28px;
      line-height: 38px;
      max-width: none;
      margin-top: 60px;
    }

    @media (max-width: 430px) {
      font-size: 25px;
      line-height: 33px;
      text-align: center;
      margin-top: 40px;
    }

  }


  .swiper-button-prev {
    background: url(${Prev});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 80px;
    width: 80px;

    @media (max-width: 899px) {
      /* height: 50px;
      width: 50px; */

      top: calc(30px);
      left: 0;
      position: absolute;
    }
    

    &::after {
      content: none;
    }
  }

  .swiper-button-next {
    background: url(${Next});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 80px;
    width: 80px;

    @media (max-width: 899px) {
      /* height: 50px;
      width: 50px; */
      top: calc(30px);
      right: 0;
      position: absolute;
    }

    /* top: calc(100% - 30px);
    right: 0;
    position: absolute; */

    &::after {
      content: none;
    }
  }
`;





const Rozpis = ({ setModal }) => {
  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  const isPlus1000 = useMediaQuery({
    query: '(min-width: 1000px)'
  });

  const [rozbaleno, setRozbaleno] = useState(false);


  return (
    <RozpisInner id="cena" rozbaleno={rozbaleno}>

      <div className="top">
        <h2 data-aos="new-animation" data-aos-delay="000">Vyberte si ten správný plán pro váš tým</h2>

        <div className="cards">
          <div className="card">
            <h3>Pouze aplikace</h3>
            <p><span>8 Kč </span>měsíčně za zaměstnance</p>
            <span className="rocne">Účtováno ročně</span>
            <LinkButtonV2 func={() => setModal(true)} text="Domluvit ukázku služby" arrow />
            <ul>
              <li>
                <img src={blueCheck} alt="check" />
                <span>Základní podpora vaší společnosti</span>
              </li>
              <li>
                <img src={blueCheck} alt="check" />
                <span>Přístup do aplikace a knihovny</span>
              </li>
              <li>
                <img src={blueCheck} alt="check" />
                <span>Základní služby a nástroje</span>
              </li>
            </ul>
          </div>

          <div className="card">
            <h3>Balíček standard</h3>
            <p><span>19 Kč </span>měsíčně za zaměstnance</p>
            <span className="rocne">Účtováno ročně</span>
            <span className="nejoblibenejsi">Nejoblíbenější</span>
            <LinkButtonV2 func={() => setModal(true)} text="Domluvit ukázku služby" arrow />
            <ul>
              <li>
                <img src={greenCheck} alt="check" />
                <span>Komplexní podpora zaměstnanců a jejich rodin</span>
              </li>
              <li>
                <img src={greenCheck} alt="check" />
                <span>24/7 experti k dispozici + concierge</span>
              </li>
              <li>
                <img src={greenCheck} alt="check" />
                <span>Neomezené využívání všech služeb a nástrojů</span>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="background">
          <img src={careIlustraceKruhy} alt="" />
        </div>
      </div>

      <div className="table">

        <div className="nadpis">
          <div className="ukazatele">
            <h3>Služby</h3>
          </div>
          {/* <div className="ukazatele">
            <h3>Popis</h3>
          </div> */}
          <div>
            <h3>Pouze aplikace</h3>
            <p>Zaměstnanec má možnost si za své peníze přikoupit lepší plán: 4 490 Kč na 3 měsíce nebo 7 990 Kč na 12 měsíců.</p>
          </div>
          <div >
            <h3>Standard</h3>
            <p>Obsahuje všechny naše služby. *Veškeré poplatky externím partnerům jsou zaměstnancem hrazeny na přímo.</p>
          </div>
        </div>

        <div className={ rozbaleno ? "sluzby" : "sluzby sluzbyHidden"}>

          <div className="pozadi">
            <div></div>
            <div></div>
          </div>
          <table>
            <thead>
              <tr class="text-left">
                <th scope="col">
                  <span class="sr-only">Feature</span>
                </th>
                <th scope="col">
                  <span class="sr-only">Scale tier</span>
                </th>
                <th scope="col">
                  <span class="sr-only">Growth tier</span>
                </th>
              </tr>
            </thead>
            <tbody>

              {rozpisSluzeb.map((sluzba, index) => (
                <tr>
                  <th>
                    <span>{sluzba.nazev}</span>
                    <div></div>
                  </th>
                  <td>
                    <span>{sluzba.appOnly === "Ano" ? <img src={greenCheck} alt="Ano" /> : <>{sluzba.appOnly === "Ne" ? <img src={noCheck} alt="Ne" /> : sluzba.appOnly}</>}</span>
                  </td>
                  <td className="standartSluzba">
                    <span>{sluzba.standard === "Ano" ? <img src={greenCheck} alt="Ano" /> : <>{sluzba.standard === "Ne" ? <img src={noCheck} alt="Ne" /> : sluzba.standard}</>}</span>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>

        {!rozbaleno && <div className="blurSekce">
          <button onClick={() => setRozbaleno(!rozbaleno)} className="rozbalit">{rozbaleno ? "Zobrazit méně" : "Zobrazit všechny služby"}</button>
        </div>}
      </div>



      
      <h2 id="priklady" data-aos="new-animation" data-aos-delay="000" className="personaH2">Pomozte vašim zaměstnancům vrátit se do práce rychleji a bez nadbytečného stresu</h2>


      <StaticQuery
        query = {graphql`
          query {
            persona1: file(relativePath: { eq: "persona1.png" }) {
              childImageSharp {
                fixed(height: 380, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }

            persona2: file(relativePath: { eq: "persona2.png" }) {
              childImageSharp {
                fixed(height: 380, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }

            persona3: file(relativePath: { eq: "persona3.png" }) {
              childImageSharp {
                fixed(height: 380, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <Swiper
              loop={true}
              pagination={true}
              spaceBetween={isLess899 ? 25 : 40}
              centeredSlides autoplay={{delay: 8000}}
              speed={500}
              grabCursor={true}
              slidesPerView={"auto"}
              navigation={true}
            >
              

              <SwiperSlide >
                <div className="persona">
                  <Img className="obrazekSmall" alt={"Petra Nováková"} fixed={data.persona1.childImageSharp.fixed} />


                  <div className="text">
                    <h3>Petra Nováková</h3>
                    <span>43 let, vedoucí marketingového oddělení</span>
                    <p>Petře náhle zemřela maminka při lyžování v Alpách. Podobnou situaci nikdy neřešila a je na pokraji svých sil a zhroucení. V práci navíc dokončuje důležitý projekt a musí se také starat o své dvě malé děti.<br/><br/>Je zmatená, smutná a bojí se, aby na nic nezapomněla.</p>
                    <ul>
                      <li>
                        <img src={greenCheck} alt="check" />
                        <span>Okamžitě zařídíme repatriaci maminky ze zahraničí.</span>
                      </li>
                      <li>
                        <img src={greenCheck} alt="check" />
                        <span>Vytvoříme přehledný plán, co vše se musí zařídit a asistujeme při plánování pohřbu.</span>
                      </li>
                      <li>
                        <img src={greenCheck} alt="check" />
                        <span>Propojíme Petru se zkušenou terapeutkou, která jí pomůže ztrátu lépe zpracovat.</span>
                      </li>
                    </ul>
                  </div>


                </div>
              </SwiperSlide>


              <SwiperSlide >
                <div className="persona">

                  <Img className="obrazekSmall" alt={"Jan Svoboda"} fixed={data.persona2.childImageSharp.fixed} />

                  <div className="text">
                    <h3>Jan Svoboda</h3>
                    <span>33 let, seniorní programátor</span>
                    <p>Honza má dlouhodobě nemocného tatínka, se kterým to bohužel nevypadá vůbec dobře. Všechny záležitosti zařizuje on se svou maminkou. Tatínek podnikal, má mnoho zahraničních účtů, pojištění a nějaké půjčky.<br/><br/>Oba mají problémy se ve všem vyznat. Současně chtějí tatínkovi poskytnout co nejlepší péči v posledních týdnech jeho života.</p>
                    <ul>
                      <li>
                        <img src={greenCheck} alt="check" />
                        <span>Díky naším kontaktům zařídíme hospicovou péči v blízkosti jejich bydliště.</span>
                      </li>
                      <li>
                        <img src={greenCheck} alt="check" />
                        <span>Po úmrtí si denně s Honzou a jeho maminkou voláme. Rušíme všechny zahraniční účty, řešíme životní pojištění a pomáháme jim s účetnictvím.</span>
                      </li>
                      <li>
                        <img src={greenCheck} alt="check" />
                        <span>Jakmile dojde na dědicné řízení, propojujeme je se zkušeným realitním makléřem na odprodej rodinného domu.</span>
                      </li>
                    </ul>
                  </div>


                </div>
              </SwiperSlide>


              <SwiperSlide >
                <div className="persona">
                  <Img className="obrazekSmall" alt={"Martina Dvořáková"} fixed={data.persona3.childImageSharp.fixed} />


                  <div className="text">
                    <h3>Martina Dvořáková</h3>
                    <span>29 let, IT recruiterka</span>
                    <p>Martina se svým přítelem čekala své první miminko. To bohužel porod nepřežilo a oba rodiče teď prožívají to nejtěžší období v jejich životě.<br/><br/>Rádi by se s děťátkem důstojně rozloučili, bohužel se nemohou domluvit s žádnou tradiční pohřební službou. Nemají žádou představu, jak taková věc probíhá a jaké jsou vůbec možnosti.</p>
                    <ul>
                      <li>
                        <img src={greenCheck} alt="check" />
                        <span>Jako první rodině nabídneme několik variant, jak by pohřeb mohl proběhnout a pomůžeme jim se sepsáním smuteční řeči.</span>
                      </li>
                      <li>
                        <img src={greenCheck} alt="check" />
                        <span>Po pohřbu Martině pomůžeme získat příspěvek na pohřeb dítěte, na který má ze zákona nárok.</span>
                      </li>
                      <li>
                        <img src={greenCheck} alt="check" />
                        <span>V rámci naší komunity se Martina seznámí s několika dalšími ženami, které si také prošly něčím podobným.</span>
                      </li>
                    </ul>
                  </div>


                </div>
              </SwiperSlide>



            </Swiper>
          )
        }
      }/>
        

    </RozpisInner>
  );
}











export default Rozpis;
