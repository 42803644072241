import React, { useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../Breadscrum";
import LinkButton from "../pohrby/LinkButton";
import Care1 from "../../assets/img/care1.svg";
import Care2 from "../../assets/img/care2.svg";
import Care3 from "../../assets/img/care3.svg";

import careDemo1 from "../../assets/img/careDemo1.svg";
import careDemo2 from "../../assets/img/careDemo2.svg";
import careDemo3 from "../../assets/img/careDemo3.svg";
import careDemo4 from "../../assets/img/careDemo4.svg";
import careDemo5 from "../../assets/img/careDemo5.svg";
import SekcePlaceholder from "../../assets/img/sekcePlaceholder.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

import vigmamLogo from "../../assets/img/vigvamLogo.png";
import brokenHeart from "../../assets/img/brokenHeart.svg";
import pen from "../../assets/img/pen.svg";
import megaphone from "../../assets/img/megaphone.svg";
import stamp from "../../assets/img/stamp.svg";
import calendar from "../../assets/img/calendar.svg";
import sold from "../../assets/img/sold.svg";
import glasses from "../../assets/img/glasses.svg";
import card from "../../assets/img/card.svg";
import weights from "../../assets/img/weights.svg";
import file from "../../assets/img/file.svg";
import people from "../../assets/img/people.svg";
import greenBook from "../../assets/img/greenBook.svg";
import business from "../../assets/img/business.svg";
import contracts from "../../assets/img/contracts.svg";
import insurance from "../../assets/img/insurance.svg";
import debts from "../../assets/img/debts.svg";
import govt from "../../assets/img/govt.svg";
import grave from "../../assets/img/grave.svg";
import mediator from "../../assets/img/mediator.svg";
import LinkButtonV2 from "../brandingV2/LinkButtonV2";


SwiperCore.use([Autoplay]);



const OverviewInner = styled.section`
  padding: 90px 0;

  @media (max-width: 899px) {
    padding: 60px 0;
  }

  @media (max-width: 430px) {
    padding: 40px 0;
  }

  h2 {
    margin: 0 auto;
    font-family: NewKansas;
    font-size: 36px;
    color: #142743;
    text-align: center;
    line-height: 50px;
    margin-bottom: 20px;
    width: calc(100% - 100px);

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      font-size: 32px;
      line-height: 40px;
      max-width: 600px;
    }

    @media (max-width: 430px) {
      font-size: 25px;
      line-height: 33px;
      text-align: left;
    }
  }


  h3 {
    font-family: Visuelt-Medium;
    font-size: 21px;
    color: #000000;
    line-height: 27px;
    margin-top: 25px;
    margin-bottom: 15px;

    @media (max-width: 899px) {
      font-size: 18px;
    }
    
    
  }

  p {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #585858;
    line-height: 25px;
  }

  .desc {
    margin: 0 auto;
    font-family: Visuelt-Regular;
    font-size: 24px;
    color: rgba(0,0,0,0.55);
    text-align: center;
    line-height: 27px;
    margin-bottom: 60px;
    width: calc(100% - 50px);

    @media (max-width: 899px) {
      margin-top: 15px;
      margin-bottom: 40px;
      font-size: 20px;
    }

    @media (max-width: 430px) {
      font-size: 17px;
      line-height: 25px;
      margin-top: 15px;
      margin-bottom: 40px;
      text-align: left;
    }
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 100px);
    margin: 0 auto;
    max-width: 1150px;

    @media (max-width: 899px) {
      display: block;
      width: calc(100% - 50px);
      max-width: 600px;
    }

    .left {
      max-width: 350px;
      order: 1;

      @media (max-width: 899px) {
        max-width: none;
        margin-top: 60px;
      }

      ul {
        padding: 0;
      }

      li {
        list-style: none;
        margin-bottom: 40px;
      }
    }

    .right {
      order: 2;
      background: #F4F3F0;
      border-radius: 10px;
      max-width: 730px;
      width: calc(100% - 400px);
      text-align: center;
      padding-top: 60px;
      padding-bottom: 60px;

      @media (max-width: 899px) {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
      }

      .ukazka {
        @media (max-width: 1150px) {
          width: 391px !important;
          height: 235px !important;
        }

        @media (max-width: 899px) {
          width: 251px !important;
          height: 153px !important;
        }
      }

      .swiper-container {
        padding-bottom: 20px;
        margin-bottom: -20px;
      }

      h3 {
        margin: 0 auto;
        max-width: 600px;
        margin-bottom: 15px;
        margin-top: 40px;
        max-width: calc(100% - 50px);

        @media (max-width: 899px) {
          margin-bottom: 20px;
          margin-top: 25px;
          max-width: calc(100% - 30px);
        }
      }

      p {
        margin: 0 auto;
        max-width: 600px;
        max-width: calc(100% - 50px);
        margin-bottom: 65px;

        @media (max-width: 899px) {
          margin-bottom: 30px;
          max-width: calc(100% - 30px);
        }
      }

      .sekce {
        text-align: left;
        background: #FFFFFF;
        box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
        border-radius: 5px;
        width: 176px;
        padding: 15px;
        height: fit-content;

        @media (max-width: 899px) {
          padding: 10px;
        }

        .imageWrap {
          background: #f4f4f4;
          border-radius: 100%;
          width: 70px;
          height: 70px;
          position: relative;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 46px;
          }
        }

        img {
          @media (max-width: 899px) {
            max-height: 40px;
            max-width: 40px;
          }
        }

        h4 {
          font-family: Visuelt-Medium;
          font-size: 16px;
          color: #000000;
          margin-top: 15px;
          margin-bottom: 15px;

          @media (max-width: 899px) {
            font-size: 14px;
            margin-top: 7px;
            margin-bottom: 7px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 14px;
          color: #727272;
          line-height: normal;
          margin: 0;
          max-width: none;

          @media (max-width: 899px) {
            font-size: 13px;
          }
        }
      }
    }
  }


  

  .mainBubble {
    background: #F2F0EC;
    max-width: 1150px;
    padding: 50px 65px;
    padding-bottom: 0;
    border-radius: 20px;
    margin: 0 auto;
    width: calc(100% - 50px - 65px*2);

    @media (max-width: 1150px) {
      padding: 50px 50px;
      padding-bottom: 0;
      width: calc(100% - 50px - 50px*2);
    }

    @media (max-width: 899px) {
      width: calc(100% - 50px - 40px*2);
      padding: 40px;
      max-width: 800px;
    }

    @media (max-width: 600px) {
      width: calc(100% - 20px - 15px*2);
      padding: 30px 15px;
    }

    .nav {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 899px) {
        display: block;
        text-align: center;
        vertical-align: baseline;
      }

      @media (max-width: 430px) {
        text-align: left;
      }

      button {
        width: 100%;
        padding: 0;
        border: none;
        background: none;
        font-family: Visuelt-Regular;
        font-size: 17px;
        color: #4E2E19;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #4E2E19;
        cursor: pointer;

        @media (max-width: 1150px) {
          height: 50px;
          padding: 0 15px;
        }

        @media (max-width: 899px) {
          display: inline-block;
          width: fit-content;
          font-size: 16px;
          border: 2px solid #b9b9b9;
          border-radius: 50px;
          height: fit-content;
          padding: 10px 15px;
          margin: 5px;
        }

        @media (max-width: 600px) {
          font-size: 14px;
          padding: 5px 8px;
        }

        @media (max-width: 370px) {
          font-size: 13px;
          padding: 3px 6px;
          margin: 3px;
        }
        
        
        &:hover {
          font-family: Visuelt-Medium;

        }
      }

      .active {
        font-family: Visuelt-Medium;
        border-bottom: 3px solid #4E2E19;
        padding-bottom: 13px;

        @media (max-width: 899px) {
          border: 2px solid #4E2E19;
          padding: 10px 15px;        
        }

        @media (max-width: 600px) {
          font-size: 14px;
          padding: 5px 8px;
        }

        @media (max-width: 370px) {
          font-size: 13px;
          padding: 3px 6px;
          margin: 3px;
        }

      }

    }

    .content {
      display: flex;
      overflow: hidden;
      justify-content: space-between;

      @media (max-width: 899px) {
        flex-direction: column-reverse;
      }

      .left {
        padding: 60px 0;
        width: calc(100% - 400px);
        max-width: 600px;

        @media (max-width: 1150px) {
          width: calc(100% - 350px);
          max-width: 700px;
        }

        @media (max-width: 899px) {
          width: 100%;
          padding: 0;
          max-width: none;
          margin-left: auto;
          margin-right: auto;
        }

        h3 {
          font-family: NewKansas;
          font-size: 30px;
          color: #3D1F0B;
          line-height: 50px;
          margin-top: 0;

          @media (max-width: 899px) {
            margin: 0 auto;
            font-size: 28px;
            text-align: center;
            margin-top: 20px;
          }

          @media (max-width: 430px) {
            font-size: 25px;
            line-height: 33px;
            text-align: left;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 17px;
          color: #3D1F0B;
          line-height: 26px;
          margin: 30px 0;

          @media (max-width: 899px) {
            text-align: center;
            margin: 30px auto;
          }

          @media (max-width: 430px) {
            font-size: 16px;
            line-height: 24px;
            text-align: left;
          }
        }

        ul {
          padding: 0;
          margin: 0;
          margin-bottom: 30px;

          @media (max-width: 899px) {
            text-align: center;
          }

          @media (max-width: 430px) {
            text-align: left;
          }

          li {

            list-style: none;
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #3D1F0B;
            display: inline-block;
            background: #F2F0EC;
            border-radius: 5px;
            padding: 7px 11px;
            margin-right: 10px;
            margin-bottom: 10px;
            border: 1px solid #D8D8D8;

            @media (max-width: 899px) {
              padding: 6px 8px;
              font-size: 15px;
            }

            @media (max-width: 430px) {
              padding: 5px 6px;
              font-size: 14px;
              margin-right: 7px;
              margin-bottom: 7px;
            }
          }
        }

        button {
          border-radius: 5px;
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #fff;
          background: #A38E80;
          padding: 12px 20px;

          @media (max-width: 899px) {
            display: block;
            margin-left: auto;
            margin-right: auto;
          }

          @media (max-width: 430px) {
            margin-left: 0;
          }

          &:hover {
            background: #938073;
          }
        }
      }

      img {
        width: 350px;
        margin-bottom: -100px;

        @media (max-width: 1150px) {
          width: 330px;
          margin-bottom: 0;
        }

        @media (max-width: 899px) {
          max-width: 250px;
          width: calc(100% - 40px);
          margin: 0 auto;
          margin-top: 30px;
        }

        @media (max-width: 430px) {
          text-align: left;
          margin-left: 0;

        }
      }
    }
  }

  .features {
  
    max-width: 1150px;
    width: calc(100% - 100px);
    margin: 0 auto;

    margin-top: 60px;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      max-width: 600px;
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 899px) {
        display: block;
      }

      li {
        list-style: none;
        max-width: 350px;
        width: calc(100%/3 - 20px);

        @media (max-width: 899px) {
          width: 100%;
          max-width: 100%;
          margin-bottom: 50px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  
  }
  
`;





const Overview = ({ setModal }) => {
  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const isPlus1000 = useMediaQuery({
    query: '(min-width: 1000px)'
  });



  let sekceData = [
    {
      img: vigmamLogo,
      h4: "Poradna Vigvam",
      p: "Potřebujete si s někým popovídat?",
    },
    {
      img: brokenHeart,
      h4: "Průvodce truchlením",
      p: "Řada článků, které vás provedou truchlením",
    },
    {
      img: mediator,
      h4: "Rodinný mediátor",
      p: "Zkušený mediátor vám pomůže lépe komunikovat",
    },
    {
      img: pen,
      h4: "Tvůrce parte",
      p: "Profesionálně vytvořené smuteční oznámení",
    },
    {
      img: megaphone,
      h4: "Oznámení smrti",
      p: "Zařídíme nekrolog a informujeme všechny blízké",
    },
    {
      img: stamp,
      h4: "Státní příspěvky",
      p: "Zajistíme pro vás všechny benefity a důchody",
    },
    {
      img: calendar,
      h4: "Organizace pohřbu",
      p: "Naplánujeme za vás jedinečné rozloučení",
    },
    {
      img: sold,
      h4: "Prodej nemovitosti",
      p: "Vyklizení a prodej nemovitosti makléřem",
    },
    {
      img: glasses,
      h4: "Hledání profesionálů",
      p: "Najdeme a objednáme vám pomoc v každém oboru",
    },
    {
      img: card,
      h4: "Zavírání digitálních účtů",
      p: "Ukončíme předplatné, účty a sociální sítě",
    },
    {
      img: business,
      h4: "Smrt podnikatele",
      p: "Převod firmy, daňové přiznání a ostatní povinnosti",
    },
    {
      img: contracts,
      h4: "Převody smluv",
      p: "Dodávka vody, plynu, elektřiny a smlouva telefonu",
    },
    {
      img: insurance,
      h4: "Životní pojištění",
      p: "Zastoupíme vás u pojišťovny až do vyplacení peněz",
    },
    {
      img: debts,
      h4: "Vyporádání dluhů",
      p: "Asistence při vypořádání všech dluhů a podhledávek",
    },
    {
      img: weights,
      h4: "Dědické řízení",
      p: "Provedeme a vysvětlíme vám celý právní proces",
    },
    {
      img: file,
      h4: "Úložiště souborů",
      p: "Všechny dokumenty na jednom místě",
    },
    {
      img: people,
      h4: "Komunita",
      p: "Nechte si poradit od ostatních lidí",
    },
    {
      img: greenBook,
      h4: "Návody",
      p: "Knihovna odborných návodů na všechna témata",
    },
    {
      img: grave,
      h4: "Hřbitovy",
      p: "Kamenictví a komunikace se hřitovní správou",
    },
    {
      img: govt,
      h4: "Matrika a důchod",
      p: "Povinnosti na matrice a odhlášení důchodu",
    },    
  ];



  let pocitadlo = 0;


  let stages = [
    {
      heading: "Personalizovaný plán",
      p: "Každému zaměstnanci a jeho rodině vytvoříme vlastní přehledný plán na 12 měsíců dopředu. Po celou dobu jim poskytujeme komplexní péči, užitečné nástroje a neomezené konzultace s experty z oboru. Mají tak jistotu, že se na nás mohou obrátit opravdu s čímkoliv a my jim vždy pomůžeme.", 
      img: careDemo1,
      features: [
        "Plánování s naším poradcem",
        "Úkolníček na míru",
        "Příprava na ztrátu",
        "Organizace pohřbu",
        "Zastupování na matrice",
        "Sdílení s rodinou",
        "Komunikace s kameníkem a hřbitovy",
        "Převody smluv, účtů, předplatných",
      ],
    },
    {
      heading: "Expertní podpora",
      p: "Naši empatičtí experti jsou tu pro vaše zaměstnance ve dne, v noci. Ať už řeší své duševní zdraví či čistě administrativní úkoly, pomůžeme jim. Nebo celou záležitost za ně rovnou vyřešíme. Asistujeme jim rádi i několikrát denně, aby měli jistotu, že vše dělají správně a na nic nezapomněli.",
      img: careDemo2,
      features: [
        "24/7 experti na chatu, videohovoru a e-mailu",
        "Concierge asistenční služby",
        "Rodinný mediátor",
        "Stabilizační služby",
        "Propojení s terapeutem",
        "Obvolávání blízkých",
        "Podpůrná skupina pro děti",
        "Průvodce truchlením",
      ],
    },
    {
      heading: "Pozůstalost a finance",
      p: "Správně se zorientovat v řešení pozůstalosti a financí je pro rodinu téměř nadlidský úkol. Vaši zaměstnanci budou mít ale jistotu, že stojíme po jejich boku. Celé fungování jim lidsky vysvětlíme a pomůžeme za ně vyřešit spousty časově náročných administrativních úkolů.",
      img: careDemo3,
      features: [
        "Převody smluv",
        "Vyplacení částky životního pojištění",
        "Žádost o pohřebné",
        "Sociální příspěvky",
        "Ocenění nemovitosti",
        "Průvodce dědickým řízením",
        "Zrušení důchodu zesnulého",
        "Sirotčí a vdovský důchod",
        "Zjištění dluhů",
      ],
    },  
    {
      heading: "Nástroje a úspora času",
      p: "Nabízíme řadu užitečných nástrojů a služeb pro šetření práce a času vašich zaměstnanců. Nezapomínáme ani na jejich duševní zdraví. Pomůžeme jim s psychickými překážkami, při byrokracii, každodenními úkoly nebo i s rušením desítek různých zapomenutých účtů a předplatných.",
      img: careDemo4,
      features: [
        "Tvorba parte",
        "Rušení digitálních účtů",
        "Energie a domácnost",
        "Telefon, internet a předplatné",
        "Členské poplatky",
        "Vyklizení nemovitosti",
        "Zařízení nekrologu",
        "Úložiště souborů",
      ],
    },
    {
      heading: "Návody a komunita",
      p: "Vaši zaměstnanci dostanou přístup do naší rozsáhlé knihovny plné návodů a srozumitelně napsaných expertních článků. V případě potřeby je kamkoliv dále díky naším kontaktům propojíme. V komunitě ostatních lidí v podobné situaci si mohou navzájem radit a podporovat se.",
      img: careDemo5,
      features: [
        "Knihovna 300+ návodů a postupů",
        "Podpurná skupina na FB",
        "Propojení s 15+ ověřenými společnostmi",
        "Partnerský účetní, právník, realitní makléř a finanční poradce",
      ],
    }
  ];

  const [stage, setStage] = useState(0);


  return (
    <StaticQuery
      query = {graphql`
        query {
          careProdukt: file(relativePath: { eq: "careProdukt.png" }) {
            childImageSharp {
              fixed(height: 329, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <OverviewInner id="ukazka">
          <h2 data-aos="new-animation" data-aos-delay="0">Přátelský průvodce vším, co musíte řešit ohledně smrti blízkého</h2>
          <p className="desc" data-aos="new-animation" data-aos-delay="100">Administrativní i psychická podpora 24/7</p>

          <div className="mainBubble">
            <div className="nav">
              {stages.map((s, index) => {

                console.log(index);
                  return (
                    <button key={index} className={index === stage ? "active" : ""} onClick={() => setStage(index)}>{s.heading}</button>
                  );
                })}
            </div>

            <div className="content">

              <div className="left" data-aos="new-animation" data-aos-delay="200">
                <h3>{stages[stage].heading}</h3>
                <p>{stages[stage].p}</p>

                <ul>
                  {stages[stage].features.map((f, index) => {
                    return (
                      <li key={index}>{f}</li>
                    );
                  })}
                </ul>

                <LinkButtonV2 func={() => {
                  if (stage < 4) {
                    setStage(stage + 1);
                  } else {
                    setStage(0);
                  }
                }} text="Pokračovat" />

              </div>

              <img data-aos="new-animation" data-aos-delay="400" src={stages[stage].img} alt={stages[stage].heading} />
              {/* <img src={careDemo2} alt="" /> */}
              {/* <img src={careDemo3} alt="" /> */}
              {/* <img src={careDemo4} alt="" /> */}
              {/* <img src={careDemo5} alt="" /> */}


            </div>
          </div>

          <div className="features">
            <ul>
              <li data-aos="new-animation" data-aos-delay="0">
                <img alt="Ztracený čas" src={Care1} />
                <h3>Ušetřete vašemu týmu stovky hodin ztraceného času a stres z neznáma</h3>
                <p>Ke každému případu přistupujeme individuálně. Už od prvního hovoru s klientem pro něj vše naplánujeme a poradíme.</p>
              </li>
              <li data-aos="new-animation" data-aos-delay="200">
                <img alt="Budujte kulturu" src={Care2} />
                <h3>Budujte kulturu, kde je o všechny postaráno za každé situace</h3>
                <p>Péče o duševní zdraví je klíčová v každé firmě. Ukažte lidem, že i v té největší krizi se za ně postavíte, pomůžete jim a mohou vám věřit.</p>
              </li>
              <li data-aos="new-animation" data-aos-delay="400">
                <img alt="Zvyšte produktivitu" src={Care3} />
                <h3>Zvyšte produktivitu a zabraňte zbytečné fluktuaci zaměstnanců</h3>
                <p>Umožněte lidem se soustředit na svou práci, zatímco my se postaráme o nutnou administrativu. Rycheji se tak vrátí do normálu.</p>
              </li>
            </ul>
          </div>

        </OverviewInner>
      )}
    />
  );
}











export default Overview;
