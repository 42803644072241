import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import SupportPanel from "../components/SupportPanel";
import Rozcestnik from "../components/Rozcestnik";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';

import JumbotronCare from "../components/care/JumbotronCare";
import JumbotronCare2 from "../components/care/JumbotronCare2";
import Overview2 from "../components/care/Overview2";
import Problems2 from "../components/care/Problems2";
import Stats2 from "../components/care/Stats2";
import Trust from "../components/care/Trust";
import Pricing2 from "../components/care/Pricing2";
import FAQ from "../components/FrequentlyAskedQ";
import DemoModal from "../components/care/DemoModal";
import Rozpis from "../components/care/Rozpis";
import Inspirace from "../components/care/Inspirace";



const faqData = [
  {
    otazka: "Nabízíte i jednorázové vstupy do platformy?",
    odpoved: `V tuto chvíli nabízíme pouze paušální přístup pro celou firmu vč. všech zaměstanců a jejich rodin na neomezené množství času. Jednorázový upgrade si může zaplatit sám zaměstnanec pouze v případě, že už vaše společnost využívá alespoň našeho základního plánu za 8 Kč PEPM. V tomto případě ale uživateli nastavujeme časový limit na využívání rozšířených funkcí platformy: 4 490 Kč na 3 měsíce nebo 7 990 Kč na 12 měsíců. V plánu standard je vše pro zaměstnance již v ceně bez žádných omezení.<br/><br/>Služba je takto nastavená z jednoduchého důvodu. Spousta lidí se po smrti blízkého uzavře sama do sebe a nijak ztrátu s firmou nekomunikuje. Stydí se, nechtějí nikoho obtěžovat anebo to prostě berou jen jako soukromou věc. To je špatně pro obě strany. Všechny problémy totiž úplně stejně zasahují do jejich času, mentálního zdraví, soukromého a pracovního života. Chceme jim proto dát možnost se anonymně připojit, např. pouze přes kód ve firemním interanetu nebo wiki a pozvat neomezeně další rodiné příslušníky. Takové anonymity a rozsahu služeb zkrátka nejde v rámci jedorázových plánů docílit.<br/><br/>Každá společnost současně dostane odznáček "Ověřeného empatického zaměstnavatele", který mohou sdílet na svých kariérních stránkách.`
  },
  {
    otazka: "Je v ceně zahrnutý i pohřeb?",
    odpoved: "Položky, jako je rakev, pronájem smuteční síně apod. účtuje zákazníkovi pohřební služba sama. Naše služba zahrnuje pomoc s výběrem správné pohřební služby a organizaci. V případě zájmu můžeme ve vybraných regionech uspořádat pohřeb v rámci naší pohřební služby."
  },
  {
    otazka: "Jak se zaměstnanec přihlásí do platformy?",
    odpoved: "Existují dva typy přístupů. Jeden pro HR a jeden pro zaměstnance. Ve chvíli, kdy zaměstnanci zemře blízký, zaměstnanec využije univerzálního firemního kódu, který odemkne přístup do platformy. Možností je také generování jednorázových kódů, např. u konktréntních partnerů. Webová aplikace potom zaměstnance automaticky vším provede a spojí ho s naší lidskou podporou."
  },
  {
    otazka: "Mohu se přihlásit i když nejsem firma?",
    odpoved: `Na verzi pro jednotlivce stále pracujeme. V tuto chvíli ještě není přístupná.`
  },
  {
    otazka: "Jak dlouho má zaměstnanec do platformy přístup?",
    odpoved: "Tak dlouho, jak bude potřebovat. Každý proces úmrtí je velmi indiviuální, vašim zaměstnancům budeme pomáhat tak, jak dlouho budou naši asistenci potřebovat. V průměru se bavíme o 9-13 měsících."
  },
  {
    otazka: "Je možné se do platformy přihlásit i delší dobu po úmrtí blízkého?",
    odpoved: "Ano, každý případ je individuální. Jsme připraveni poskytnout pomoc v případech, kdy se zaměstnanec na smrt blízkého teprve chystá, ihned po úmrtí nebo i pokud už od něj uplynula delší doba."
  },
  {
    otazka: "Jste v Goodbye schopni poskytnout profesionální právní a daňové poradenství?",
    odpoved: "Goodbye není advokátní ani účetní společnost. Nemůžeme nahradit práci notáře, právníků ani daňového poradce. Našim zákazníkům nabízíme veškeré informace a péči spojenou s těmito tématy a následně je propojujeme s odborníky v daném oboru."
  },
  {
    otazka: "Jakým způsobem zajišťujete bezpečnost dat?",
    odpoved: "Bezpečnost vašich dat, zaměstnanců i celé organizace je pro nás velmi důležité. Osobní údaje uživatelů zpracováváme v souladu s nařízením GDPR, které nastavuje nejvyšší úroveň ochrany soukromí a osobních údajů na světě. Žádné třetí strany nemají přístup k vašim interním datům, která jsou bezpečně šifrována a ukládána na AWS v EU. Vše tedy funguje tak, aby citlivá data zůstala přesně tam, kde mají."
  },
];


const CareInner = styled.section`
  background-color: #FBFBFA;
  padding-bottom: 1px;


  @media (max-width: 899px) {
    .aos-animate {
      -o-transition-property: none !important;
      -moz-transition-property: none !important;
      -ms-transition-property: none !important;
      -webkit-transition-property: none !important;
      transition-property: none !important;

      -o-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -webkit-transform: none !important;
      transform: none !important;

      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      -ms-animation: none !important;
      animation: none !important;
    }
  }
`;

const Care = ({ data }) => {

  const [isModal, setModal] = useState(false);

  const isPlus900 = useMediaQuery({
    query: '(min-width: 899px)'
  });

  const isLess1150 = useMediaQuery({
    query: '(max-width: 1150px)'
  });

  return (
    <Layout 
      customCtaText={"Domluvit demo"}
      customCtaLink={"https://tinyurl.com/goodbye-care"}
      customLoginText={"Aplikace"}
      customLoginLink={"https://care.goodbye.cz/"}
      subMenuLong={isLess1150}
      subMenuItems={[
        {
          name: "Dopad na zaměstnance",
          url: "#dopad",
        },
        {
          name: "Platforma",
          url: "#ukazka",
        },
        {
          name: "Výsledky",
          url: "#vysledky",
        },
        {
          name: "Porovnání plánů",
          url: "#cena",
        },
        {
          name: "Příklady použití",
          url: "#priklady",
        },
        {
          name: "Nástroje pro HR",
          url: "#proHR",
        },
        {
          name: "FAQs",
          url: "#faqs",
        },
      ]}
    >

      <SEO
        title="Benefit Goodbye Care - Péče o zaměstnance po úmrtí blízkého"
        //Podpořte zaměstnance v nejtěžším období jejich života | Care
        // title="Goodbye Care - Benefit pro zaměstnance v těžké životní situaci"

        customTitle
        description="Platforma a 24/7 asistenční služba, která pomůže lidem ve vašem týmu se zorientovat a vyřešit všechny úkoly související se smrtí jejich blízkého."
        image="/careOg.png"
      />
      

      <CareInner>

        {isModal && <DemoModal setModal={setModal} />}

        {/* <JumbotronCare setModal={setModal} /> */}
        <JumbotronCare2 setModal={setModal} />

        <Problems2 setModal={setModal} />

        <Overview2 setModal={setModal} />



        <Stats2 setModal={setModal} />

        <Trust setModal={setModal} />

        <Pricing2 setModal={setModal} />

        {/* <Rozpis setModal={setModal} /> */}
        <Inspirace setModal={setModal} />
        
        <FAQ data={faqData} /> 

      </CareInner>
    </Layout>
  );
}




// export const query = graphql`

// `;


export default Care;
